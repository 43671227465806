import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
}

export default function GlobalContextProvider({ children }) {
  const [currentNavTitle, setCurrentNavTitle] = useState('');

  return (
    <GlobalContext.Provider value={{
      currentNavTitle,
      setCurrentNavTitle
    }}>
      { children }
    </GlobalContext.Provider>
  )
}
