import GlobalContextProvider from '../lib/contexts/global-context';
import '../styles/app.scss';
import Script from 'next/script';

export function reportWebVitals({ id, name, label, value }) {
  // Catch incase this function is undefined
  if (window.gtag && typeof window.gtag === 'function') {
    window.gtag('event', name, {
      event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
}

function MyApp({ Component, pageProps }) {
  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
        <>
          {/* <!-- Google Tag Manager --> */}
          <Script id="googletagmanager" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
            `}
          </Script>
          {/* <!-- End Google Tag Manager --> */}
        </>
      )}
      {process.env.NEXT_PUBLIC_HOTJAR_ID && (
        <>
          {/* <!-- Hotjar Tracking Code --> */}
          <Script id="hotjar">
            {`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
          </Script>
        </>
      )}
      {process.env.NEXT_PUBLIC_ADROLL_ADV_ID && process.env.NEXT_PUBLIC_ADROLL_PIX_ID && (
        <>
          {/* <!-- Adroll --> */}
          <Script id="adroll">
            {`
              adroll_adv_id = "${process.env.NEXT_PUBLIC_ADROLL_ADV_ID}";
              adroll_pix_id = "${process.env.NEXT_PUBLIC_ADROLL_PIX_ID}";
              /* OPTIONAL: provide email to improve user identification */
              /* adroll_email = "username@example.com"; */
              (function () {
                var _onload = function(){
                if (document.readyState && !/loaded|complete/.test(document.readyState)){setTimeout(_onload, 10);return}
                if (!window.__adroll_loaded){__adroll_loaded=true;setTimeout(_onload, 50);return}
                var scr = document.createElement("script");
                var host = (("https:" == document.location.protocol) ? "https://s.adroll.com" : "//a.adroll.com");
                scr.setAttribute('async', 'true');
                scr.type = "text/javascript";
                scr.src = host + "/j/roundtrip.js";
                ((document.getElementsByTagName('head') || [null])[0] ||
                  document.getElementsByTagName('script')[0].parentNode).appendChild(scr);
              };
              if (window.addEventListener) {window.addEventListener('load', _onload, false);}
              else {window.attachEvent('onload', _onload)}
              }());
            `}
          </Script>
        </>
      )}
      <GlobalContextProvider>
        <Component {...pageProps} />
      </GlobalContextProvider>
    </>
  );
}

export default MyApp;
